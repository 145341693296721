import produce from 'immer';
import { Action } from 'redux';
import {
  GuestInfoActionTypes,
  ISelectGuestAction,
} from 'lib/guest-info-collector/actions/action-types';
import {
  SetDeviceAction,
  SetErrorAction,
  SetMicrositeDataAction,
  SharedActionTypes,
} from 'lib/shared/actions/action-types';
import { IApplicationState, IReducersImmer } from 'lib/types';

export const initialState: IApplicationState = {
  microsite: {},
  guestInfoCollector: {},
  device: {
    isMobile: true,
  },
};

const reducers: IReducersImmer<IApplicationState> = (draft) => ({
  [SharedActionTypes.SET_MICROSITE_DATA]: (action: SetMicrositeDataAction) => {
    draft.microsite = action.payload;
  },
  [SharedActionTypes.SET_DEVICE]: (action: SetDeviceAction) => {
    draft.device = action.payload;
  },
  [SharedActionTypes.SET_ERROR]: (action: SetErrorAction) => {
    draft.error = action.payload;
  },
  [GuestInfoActionTypes.SELECT_GUEST]: (action: ISelectGuestAction) => {
    draft.guestInfoCollector.selected = action.payload;
  },
});

export const reducer = (
  state: IApplicationState = initialState,
  action: Action,
): IApplicationState => {
  try {
    return produce(state, (draft: IApplicationState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};
