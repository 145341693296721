const path = require('path');

const languageTags = {
  uk: 'en-GB',
  en: 'en-US',
  ie: 'en-IE',
  de: 'de-DE',
  fr: 'fr-FR',
};

const ns =
  typeof window === 'undefined'
    ? require('fs')
        .readdirSync(`./public/locales/en-GB/`)
        .map((file) => (file.endsWith('.json') ? file.replace('.json', '') : null))
        .filter(Boolean)
    : [];

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['uk', 'de', 'fr', 'ie', 'en'],
  },
  fallbackLng: 'en',
  react: {
    useSuspense: false,
  },
  localePath: (locale, namespace) => {
    const languageTag = (() => {
      if (process.env.NEXT_PUBLIC_CROWDIN_IN_CONTEXT) {
        return 'ach-UG';
      }

      return languageTags[locale] || locale;
    })();
    return path.resolve(`./public/locales/${languageTag}/${namespace}.json`);
  },
  ns,
  serializeConfig: false,
};
