import { AnalyticsContext } from '@bridebook/analytics';

export class MicrositesAnalytics extends AnalyticsContext {
  page(_name?: string, _properties: Record<string, any> = {}) {
    const name = _name || window ? window.location.pathname : '';
    const properties = {
      name,
      url: window ? window.location.href : '',
      ..._properties,
    };
    this.analytics.page(name, properties);
  }
}
