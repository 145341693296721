import Fuse, { FuseOptions } from 'fuse.js';
import { flatten, isEmpty, values } from 'ramda';
import * as R from 'remeda';
import { IGuestLight } from 'lib/types';

const searchOptions: FuseOptions<IGuestLight> = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  threshold: 0,
  location: 0,
  distance: 1000,
  maxPatternLength: 32,
  findAllMatches: true,
  minMatchCharLength: 3,
  keys: ['name'],
  includeMatches: true,
};

export const search = (value: string, guests: IGuestLight[]) => {
  // perform search
  const fuse = new Fuse(guests, searchOptions);
  // @ts-ignore
  const result: Fuse.FuseResultWithMatches<IGuestLight>[] = fuse.search(value.trim());

  // group results by head for future bundling of plus ones
  const grouped = values(R.groupBy(result, (r) => r.item.head));

  // return only one result per group of +1's
  const processed = grouped.map((plusOnesGroup) => {
    const head = plusOnesGroup.filter((p) => p.item.isHead);
    if (!isEmpty(head) && head[0]?.item.head) {
      return head[0];
    }
    return plusOnesGroup;
  });

  return flatten(processed);
};

export const getPlusOnes = (guests: IGuestLight[], guest: IGuestLight) => {
  if (!guest.isHead && !guest.head) return [];

  return (
    guests
      .filter((g) => (guest.isHead ? g.head === guest.id : g.head === guest.head))
      // Don't show passed guest in the list of plus ones
      .filter((g) => g.id !== guest.id)
  );
};

export const getPlusOnesNames = (guests: IGuestLight[], guest: IGuestLight): string[] =>
  getPlusOnes(guests, guest).map((g) => g.name);
