import { Action } from 'redux';
import guestCollectorAnalytics from 'lib/guest-info-collector/analytics';
import { MicrositesAnalytics } from 'lib/microsites-analytics';
import { IDeps } from 'lib/types';

const AnalyticsMiddleware =
  (bridebookAnalytics: MicrositesAnalytics) =>
  ({ getState }: IDeps) =>
  (next: (action: Action) => void) =>
  (action: Action) => {
    if (!process.browser) return next(action);

    guestCollectorAnalytics(action, bridebookAnalytics, getState);

    return next(action);
  };

export default AnalyticsMiddleware;
