import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import React from 'react';
import { FelaProvider } from '@bridebook/ui';
import addUKPluralUpdate from '../lib/i18n/addUKPluralUpdate';
import { wrapper } from '../lib/redux/store';
import config from '../next-i18next.config.js';

function MyApp({ Component, pageProps, renderer }: AppProps & { renderer?: any }) {
  addUKPluralUpdate();

  return (
    <FelaProvider renderer={renderer}>
      <Component {...pageProps} />
    </FelaProvider>
  );
}

export default appWithTranslation(wrapper.withRedux(MyApp), config);
