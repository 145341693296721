import { setDevice, setError, setMicrositeData } from '.';

export enum SharedActionTypes {
  SET_MICROSITE_DATA = '[Shared] Set microsite data',
  SET_DEVICE = '[Shared] Set device',
  SET_ERROR = '[Shared] Set error',
}

export type SetMicrositeDataAction = ReturnType<typeof setMicrositeData>;
export type SetDeviceAction = ReturnType<typeof setDevice>;
export type SetErrorAction = ReturnType<typeof setError>;
