import { HYDRATE } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// @ts-ignore
import serializeError from 'serialize-error';
import uuid from 'uuid-random';
import * as Sentry from '@sentry/nextjs';
import { MicrositesAnalytics } from 'lib/microsites-analytics';
import AnalyticsMiddleware from 'lib/redux/analytics-middleware';
import { initialState, reducer } from 'lib/shared/reducer';
import { IApplicationState } from '../types';

const analytics = null;

const analyticsMiddleware = AnalyticsMiddleware(
  new MicrositesAnalytics(analytics, {
    mobileApp: false,
    sessionId: uuid(),
  }),
);

const injectMiddleware =
  (deps: any = {}) =>
  ({ dispatch, getState }: any) =>
  (next: any) =>
  (action: any) => {
    const injectAction = () => {
      const depsToInject = { ...deps };

      next(typeof action === 'function' ? action({ ...depsToInject, dispatch, getState }) : action);
    };

    try {
      injectAction();
    } catch (e) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(e, { extra: serializeError(e) });
      } else {
        console.error(
          'Actions must be plain objects. Use custom middleware for async actions',
          action,
          e,
        );
      }
    }
  };

const middleware = [injectMiddleware(), analyticsMiddleware];

// Logger must be the last middleware in chain.
if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger');
  middleware.push(createLogger({ collapsed: true }));
}

const reducerEnhanced = (state: IApplicationState, action: any) => {
  if (action.type === HYDRATE) {
    const nextState: IApplicationState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    if (state.guestInfoCollector.selected) {
      nextState.guestInfoCollector.selected = state.guestInfoCollector.selected;
    }

    if (state.microsite.weddingId) {
      nextState.microsite = state.microsite;
    }

    return nextState;
  } else {
    return reducer(state, action);
  }
};

export const initializeStore = (preloadedState = initialState) => createStore(
    reducerEnhanced,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
