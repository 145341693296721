import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { getPlusOnes, getPlusOnesNames } from 'lib/guest-info-collector/search';
import { IApplicationState, IGuestLight } from 'lib/types';

const partnerName1 = (state: IApplicationState) => state.microsite.partnerName1;
const partnerName2 = (state: IApplicationState) => state.microsite.partnerName2;
const guests = (state: IApplicationState) => state.microsite.guests;
const selectedId = (state: IApplicationState) => state.guestInfoCollector.selected;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

/**
 * Simple boolean check if couple names have been entered
 */
export const hasPartnerNames = createSelector(
  [partnerName1, partnerName2],
  (partnerName1, partnerName2) => Boolean(partnerName1 && partnerName2),
);

/**
 * Returns "Partner 1 & Partner 2"
 */
export const getPartners = createSelector(
  [partnerName1, partnerName2],
  (partnerName1 = 'Partner', partnerName2 = 'Partner') => `${partnerName1} & ${partnerName2}`,
);

/**
 * Returns guest selected from search suggestions
 */
export const getSelectedGuest = createSelector(
  [guests, selectedId],
  (guests, selectedId): IGuestLight | undefined => guests?.find((guest) => guest.id === selectedId),
);

export const getLinkedGuestsIds = createCachedSelector(
  guests,
  (_: IApplicationState, guest?: IGuestLight) => guest,
  (guests, guest) => (guest ? getPlusOnes(guests || [], guest).map((g) => g.id) : undefined),
)((_, guest) => guest?.id || '');

export const getGuestPlusOnes = createCachedSelector(
  guests,
  (_: IApplicationState, guest: IGuestLight | undefined) => guest,
  (guests, guest) => (guest ? getPlusOnesNames(guests || [], guest) : undefined),
)((_, guest) => guest?.id || '');
