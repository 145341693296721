import {
  submitFormFailedAnalytics,
  submitFormSuccessAnalytics,
} from 'lib/guest-info-collector/analytics/actions';

export enum GuestInfoAnalyticsActionTypes {
  PAGE_LOAD = '[Analytics] Page loaded',
  COMPLETED_INITIAL = '[Analytics] Completed initial page',
  COMPLETED_SEARCH = '[Analytics] Completed search page',
  SUBMIT_FORM_FAILED = '[Analytics] Submit form failed',
  SUBMIT_FORM_SUCCESS = '[Analytics] Submit form success',
  TRIGGERED_CONFETTI = '[Analytics] Triggered confetti',
}

export type SubmitFormFailedAnalyticsAction = ReturnType<typeof submitFormFailedAnalytics>;

export type SubmitFormSuccessAnalyticsAction = ReturnType<
  ReturnType<typeof submitFormSuccessAnalytics>
>;
