import { Action } from 'redux';
import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { GuestCollectorFormData } from '@bridebook/toolbox/src/types';
import {
  GuestInfoAnalyticsActionTypes,
  SubmitFormFailedAnalyticsAction,
  SubmitFormSuccessAnalyticsAction,
} from 'lib/guest-info-collector/analytics/action-types';
import { getGuestPlusOnes, getSelectedGuest } from 'lib/guest-info-collector/selectors';
import { MicrositesAnalytics } from 'lib/microsites-analytics';
import { IApplicationState } from 'lib/types';

const filterLabelProps = (props: Record<string, any>) => props;

export const getPropertiesGeneric = (getState: () => IApplicationState) => ({
  guestWeddingId: getState().microsite.weddingId,
});

export const getGuestMicrositePropertiesGeneric = (
  getState: () => IApplicationState,
  data: GuestCollectorFormData,
) => {
  const guest = getSelectedGuest(getState());
  return {
    guestName: guest?.name || '',
    guestLinkedNames: getGuestPlusOnes(getState(), guest),
    guestMessage: data.message,
    guestEmail: data.email,
    guestPhone: data.phone,
    guestAddress: {
      city: data.city,
      country: data.country,
      postalCode: data.postalCode,
      street: [data.street0, data.street1],
    } as IGuest['address'],
  };
};

export default function guestCollectorAnalytics(
  action: Action,
  bridebookAnalytics: MicrositesAnalytics,
  getState: () => IApplicationState,
): void {
  const { track } = bridebookAnalytics.getMethods('Guest Collector', filterLabelProps);

  switch (action.type) {
    case GuestInfoAnalyticsActionTypes.PAGE_LOAD: {
      bridebookAnalytics.page();
      break;
    }

    case GuestInfoAnalyticsActionTypes.COMPLETED_INITIAL: {
      track({
        event: 'Completed guest info collector initial page',
        ...getPropertiesGeneric(getState),
      });
      break;
    }

    case GuestInfoAnalyticsActionTypes.COMPLETED_SEARCH: {
      track({
        event: 'Completed guest info collector search page',
        ...getPropertiesGeneric(getState),
      });
      break;
    }

    case GuestInfoAnalyticsActionTypes.SUBMIT_FORM_FAILED: {
      const {
        payload: { data, message },
      } = action as SubmitFormFailedAnalyticsAction;
      track({
        event: 'Failed to edit guest on microsite',
        ...getPropertiesGeneric(getState),
        ...getGuestMicrositePropertiesGeneric(getState, data),
        reasonFailedToEditMicrositeGuest: message,
      });
      break;
    }

    case GuestInfoAnalyticsActionTypes.SUBMIT_FORM_SUCCESS: {
      const {
        payload: { data },
      } = action as SubmitFormSuccessAnalyticsAction;
      track({
        event: 'Clicked to edit guest on microsite',
        ...getPropertiesGeneric(getState),
        ...getGuestMicrositePropertiesGeneric(getState, data),
      });
      break;
    }

    case GuestInfoAnalyticsActionTypes.TRIGGERED_CONFETTI: {
      track({
        event: 'Clicked confetti button on microsite',
        ...getPropertiesGeneric(getState),
      });
      break;
    }
  }
}
